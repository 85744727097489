.odometer.odometer-auto-theme, .odometer.odometer-theme-minimal {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-minimal .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon {
  display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-minimal .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-minimal .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner {
  transition: transform 2s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down .odometer-ribbon-inner {
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: transform 2s;
  transform: translateY(0);
}

/********************************************
	BREAKPOINT WIDTHS
********************************************/
/********************************************
	FONTS
********************************************/
/********************************************
	COLOURS
********************************************/
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  transition: all 0.25s ease-in;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer; }
  .carousel .control-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100); }
  .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: ''; }
  .carousel .control-disabled.control-arrow {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: inherit;
    display: none; }
  .carousel .control-prev.control-arrow {
    left: 0; }
    .carousel .control-prev.control-arrow:before {
      border-right: 8px solid #fff; }
  .carousel .control-next.control-arrow {
    right: 0; }
    .carousel .control-next.control-arrow:before {
      border-left: 8px solid #fff; }

.carousel-root {
  outline: none; }

.carousel {
  position: relative;
  width: 100%; }
  .carousel * {
    box-sizing: border-box; }
  .carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none; }
  .carousel .carousel {
    position: relative; }
  .carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px; }
  .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden; }
  .carousel .thumbs {
    transition: all 0.15s ease-in;
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap; }
  .carousel .thumb {
    transition: border 0.15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px; }
    .carousel .thumb:focus {
      border: 3px solid #ccc;
      outline: none; }
    .carousel .thumb.selected, .carousel .thumb:hover {
      border: 3px solid #333; }
    .carousel .thumb img {
      vertical-align: top; }
  .carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden; }
    .carousel.carousel-slider .control-arrow {
      top: 0;
      color: #fff;
      font-size: 26px;
      bottom: 0;
      margin-top: 0;
      padding: 5px; }
      .carousel.carousel-slider .control-arrow:hover {
        background: rgba(0, 0, 0, 0.2); }
  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height 0.15s ease-in; }
    .carousel .slider-wrapper.axis-horizontal .slider {
      -ms-box-orient: horizontal;
      display: -moz-flex;
      display: flex; }
      .carousel .slider-wrapper.axis-horizontal .slider .slide {
        flex-direction: column;
        flex-flow: column; }
    .carousel .slider-wrapper.axis-vertical {
      -ms-box-orient: horizontal;
      display: -moz-flex;
      display: flex; }
      .carousel .slider-wrapper.axis-vertical .slider {
        flex-direction: column; }
  .carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%; }
    .carousel .slider.animated {
      transition: all 0.35s ease-in-out; }
  .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: #000; }
    .carousel .slide img {
      width: 100%;
      vertical-align: top;
      border: 0; }
    .carousel .slide iframe {
      display: inline-block;
      width: calc(100% - 80px);
      margin: 0 40px 40px;
      border: 0; }
    .carousel .slide .legend {
      transition: all 0.5s ease-in-out;
      position: absolute;
      bottom: 40px;
      left: 50%;
      margin-left: -45%;
      width: 90%;
      border-radius: 10px;
      background: #000;
      color: #fff;
      padding: 10px;
      font-size: 12px;
      text-align: center;
      opacity: 0.25;
      transition: opacity 0.35s ease-in-out; }
  .carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    text-align: center;
    width: 100%; }
    @media (min-width: 960px) {
      .carousel .control-dots {
        bottom: 0; } }
    .carousel .control-dots .dot {
      transition: opacity 0.25s ease-in;
      opacity: 0.3;
      filter: alpha(opacity=30);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
      background: #fff;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      cursor: pointer;
      display: inline-block;
      margin: 0 8px; }
      .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
        opacity: 1;
        filter: alpha(opacity=100); }
  .carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff; }
  .carousel:hover .slide .legend {
    opacity: 1; }

